import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import '../styles/creative.css';

function Creatives({ abbreviation, dealId, initiative }) {
  const [channel, setChannel] = useState('');
  const [adName, setAdName] = useState('');
  const [adtype, setAdType] = useState('');
  const [version, setVersion] = useState('');
  const [color, setColor] = useState('');
  const [action, setAction] = useState('');
  const [size, setSize] = useState('');
  const [fileNames, setFileNames] = useState(() => {
    // Load initial state from local storage
    const savedFileNames = localStorage.getItem('creativesFileNames');
    return savedFileNames ? JSON.parse(savedFileNames) : [];
  });
  const [colorOtherVisible, setColorOtherVisible] = useState(false);
  const [actionOtherVisible, setActionOtherVisible] = useState(false);
  const [colorOther, setColorOther] = useState('');
  const [actionOther, setActionOther] = useState('');
  
  const sizeOptions = [
    { value: 'Social', label: 'Social' },
    { value: 'Search', label: 'Search' },
    {value: 'Responsive', label: 'Responsive'},
    { value: '06', label: '06' },
    { value: '15', label: '15' },
    { value: '30', label: '30' },
    { value: '45', label: '45' },
    { value: '60', label: '60' },
    { value: '90', label: '90' },
    { value: '120', label: '120' },
    { value: '180', label: '180' },
    { value: '1000x300', label: '1000x300' },
    { value: '1000x600', label: '1000x600' },
    { value: '1000x750', label: '1000x750' },
    { value: '1024x50', label: '1024x50' },
    { value: '1024x512', label: '1024x512' },
    { value: '1024x768', label: '1024x768' },
    { value: '1024x850', label: '1024x850' },
    { value: '1024x90', label: '1024x90' },
    { value: '1054x768', label: '1054x768' },
    { value: '1078x920', label: '1078x920' },
    { value: '1080x200', label: '1080x200' },
    { value: '1200x300', label: '1200x300' },
    { value: '1200x626', label: '1200x626' },
    { value: '1200x627', label: '1200x627' },
    { value: '1200x628', label: '1200x628' },
    { value: '1200x630', label: '1200x630' },
    { value: '1200x672', label: '1200x672' },
    { value: '1200x800', label: '1200x800' },
    { value: '120x160', label: '120x160' },
    { value: '120x240', label: '120x240' },
    { value: '120x60', label: '120x60' },
    { value: '120x600', label: '120x600' },
    { value: '120x618', label: '120x618' },
    { value: '120x619', label: '120x619' },
    { value: '120x90', label: '120x90' },
    { value: '125x125', label: '125x125' },
    { value: '1280x100', label: '1280x100' },
    { value: '1280x720', label: '1280x720' },
    { value: '1280x960', label: '1280x960' },
    { value: '1400x400', label: '1400x400' },
    { value: '160x300', label: '160x300' },
    { value: '160x500', label: '160x500' },
    { value: '160x600', label: '160x600' },
    { value: '160x700', label: '160x700' },
    { value: '180x150', label: '180x150' },
    { value: '200x200', label: '200x200' },
    { value: '200x600', label: '200x600' },
    { value: '20x480', label: '20x480' },
    { value: '234x60', label: '234x60' },
    { value: '240x240', label: '240x240' },
    { value: '250x250', label: '250x250' },
    { value: '250x360', label: '250x360' },
    { value: '278x90', label: '278x90' },
    { value: '300x100', label: '300x100' },
    { value: '300x150', label: '300x150' },
    { value: '300x200', label: '300x200' },
    { value: '300x250', label: '300x250' },
    { value: '300x300', label: '300x300' },
    { value: '300x350', label: '300x350' },
    { value: '300x480', label: '300x480' },
    { value: '300x50', label: '300x50' },
    { value: '300x60', label: '300x60' },
    { value: '300x600', label: '300x600' },
    { value: '320x100', label: '320x100' },
    { value: '320x120', label: '320x120' },
    { value: '320x150', label: '320x150' },
    { value: '320x250', label: '320x250' },
    { value: '320x320', label: '320x320' },
    { value: '320x400', label: '320x400' },
    { value: '320x460', label: '320x460' },
    { value: '320x480', label: '320x480' },
    { value: '320x50', label: '320x50' },
    { value: '320x600', label: '320x600' },
    { value: '320x80', label: '320x80' },
    { value: '330x280', label: '330x280' },
    { value: '336x280', label: '336x280' },
    { value: '336x80', label: '336x80' },
    { value: '360x280', label: '360x280' },
    { value: '36X280', label: '36X280' },
    { value: '370X250', label: '370X250' },
    { value: '375X627', label: '375X627' },
    { value: '375X667', label: '375X667' },
    { value: '468X50', label: '468X50' },
    { value: '468X60', label: '468X60' },
    { value: '480X320', label: '480X320' },
  ];

  useEffect(() => {
    // Save file names to local storage whenever they change
    localStorage.setItem('creativesFileNames', JSON.stringify(fileNames));
  }, [fileNames]);

  const handleColorChange = (e) => {
    setColor(e.target.value);
    setColorOtherVisible(e.target.value === 'Other');
  };

  const handleActionChange = (e) => {
    setAction(e.target.value);
    setActionOtherVisible(e.target.value === 'Other');
  };


  const handleSubmit = (event) => {
    event.preventDefault();

    let colorValue = color === 'Other' ? colorOther : color;
    let actionValue = action === 'Other' ? actionOther : action;
    let channelname = channel[0].toUpperCase() + channel.slice(1);
    let ad = adName[0].toUpperCase() + adName.slice(1);
    let newFileName = ""
    if (adtype) {
      newFileName = `${channelname} | ${abbreviation} | ${ad} | ${adtype}`;
    }
    else{
      newFileName = `${channelname} | ${abbreviation} | ${ad}`;
    }
    // if (version) {
    //   newFileName += ` | V-${version}`;
    // }

    // if (colorValue || actionValue) {
    //   if (!version) {
    //     newFileName += ` |`;
    //   }
    //   if (colorValue) {
    //     newFileName += ` C-${colorValue}`;
    //   }
    //   if (actionValue) {
    //     newFileName += ` CTA-${actionValue}`;
    //   }
    // }

    let contexts = [];
    if (version) {
      contexts.push(`V-${version}`);
    }
    if (colorValue) {
      contexts.push(`C-${colorValue}`);
    }
    if (actionValue) {
      contexts.push(`CTA-${actionValue}`);
    }

    if (contexts.length > 0) {
      newFileName += ` | ${contexts.join(', ')}`;
    }
    newFileName += ` | ${size}`;

    setFileNames([...fileNames, newFileName]);
  };
  const ExtractDetailsFromUploadedFile = async (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== 'text/csv') {
        alert('Please upload a valid .csv file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const data = await file.text();
    const lines = data.split('\n');

    // Assuming the first line is the header
    const header = lines[0].split(',').map(h => h.trim());

    // Column indices
    const channelIndex = header.indexOf('Channel');
    const adNameIndex = header.indexOf('Ad Name');
    const adtypeIndex = header.indexOf('Ad Type');
    const sizeIndex = header.indexOf('Size/Duration');
    const versionIndex = header.indexOf('Version');
    const colorIndex = header.indexOf('Color');
    const actionIndex = header.indexOf('Call To Action');
    const colorOtherIndex = header.indexOf('Color - Other');
    const actionOtherIndex = header.indexOf('Call To Action - Other');

    if (
        channelIndex === -1 ||
        adNameIndex === -1 ||
        sizeIndex === -1
    ) {
        alert('Required columns not found in the uploaded file.');
        event.target.value = ''; // Reset the file input
        return;
    }

    const newFileNames = [];

    // Start from line 1 to skip header
    for (let i = 1; i < lines.length; i++) {
        const row = lines[i].split(',').map(r => r.trim());

        // Ensure that required fields are not empty
        if (
            !row[channelIndex] || 
            !row[adNameIndex] || 
            !row[sizeIndex]
        ) {
            continue; // Skip rows with missing required fields
        }

        let channel = row[channelIndex].replace(/\r$/, ''); // Remove trailing \r
        let adName = row[adNameIndex].replace(/\r$/, ''); // Remove trailing \r
        let size = row[sizeIndex].replace(/\r$/, ''); // Remove trailing \r
        let adtype = row[adtypeIndex] ? row[adtypeIndex].replace(/\r$/, '') : ''; // Optional
        let version = row[versionIndex] ? row[versionIndex].replace(/\r$/, '') : ''; // Optional
        
        // Handle Color and Action fields with "Other" value
        let color = row[colorIndex] ? row[colorIndex].replace(/\r$/, '') : ''; // Optional
        if (color === 'Other' && colorOtherIndex !== -1 && row[colorOtherIndex]) {
            color = row[colorOtherIndex].replace(/\r$/, ''); // Use "Color - Other" value
        }
        let action = row[actionIndex] ? row[actionIndex].replace(/\r$/, '') : ''; // Optional
        if (size==='6'){
          size='06'
        }
        if (action === 'Other' && actionOtherIndex !== -1 && row[actionOtherIndex]) {
            action = row[actionOtherIndex].replace(/\r$/, ''); // Use "Call To Action - Other" value
        }
        let channelname = channel.charAt(0).toUpperCase() + channel.slice(1);
        let ad = adName.charAt(0).toUpperCase() + adName.slice(1);

        let newFileName = `${channelname} | ${abbreviation} | ${ad}`;

        if (adtype) {
            newFileName += ` | ${adtype}`;
        }

        let contexts = [];
        if (version) {
            contexts.push(`V-${version}`);
        }
        if (color) {
            contexts.push(`C-${color}`);
        }
        if (action) {
            contexts.push(`CTA-${action}`);
        } 

        if (contexts.length > 0) {
            newFileName += ` | ${contexts.join(', ')}`;
        }

        newFileName += ` | ${size}`;

        newFileNames.push(newFileName);
    }

    setFileNames([...fileNames, ...newFileNames]);
  };

  const handleClear=()=>{
        // Reset form fields after submission
        setChannel('');
        setAdName('');
        setAdType('');
        setVersion('');
        setColor('');
        setAction('');
        setSize('');
        setColorOther('');
        setActionOther('');
        setColorOtherVisible(false);
        setActionOtherVisible(false);

  }

  const handleRemove = (index) => {
    const updatedFileNames = fileNames.filter((_, i) => i !== index);
    setFileNames(updatedFileNames);
  };

  const downloadCSV = () => {
    const csvContent = `data:text/csv;charset=utf-8,${fileNames.map(name => `"${name}"`).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'campaigns.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
      <div className="form-container">
        <form className="creative-form" onSubmit={handleSubmit}>
          <div className="options-container">
            <div className="channel-container">
              <span>*</span>
            <label htmlFor="channel" className='input-label'>Channel:</label>
            <select
              name="Channel"
              id="channel"
              value={channel}
              onChange={(e) => setChannel(e.target.value)}
              required
            >
              <option value="" disabled>
                Channel
              </option>
              <option value="Video">Video</option>
              <option value="DOOH">DOOH</option>
              <option value="Cable">Cable</option>
              <option value="social">Social</option>
              <option value="search">Search</option>
              <option value="native">Native</option>
              <option value="display">Display</option>
              <option value="AXVS">AXVS</option>
              <option value="High Impact">High Impact</option>
              <option value="CTV">CTV</option>
              <option value="audio">Audio</option>
            </select>
            </div>
          <div className="ad-container">
            <span>*</span>
            <label htmlFor="adname" className='input-label'>Ad Name:</label>
            <input
              type="text"
              name="ad name"
              id="adname"
              placeholder="Ad Name"
              value={adName}
              onChange={(e) => setAdName(e.target.value)}
              required
            />
          </div>
          <div className="adtype">
            <div className="adtype-container">
              <label htmlFor="AdType" className='input-label'>Ad Type:</label>
              <select id='AdType' name="AdType" value={adtype} onChange={(e) => setAdType(e.target.value)}>
                <option value="" disabled>Select Ad Type</option>
                <option value="Static">Static</option>
                <option value="Carousel">Carousel</option>
                <option value="Animated">Animated</option>
                <option value="Video">Video</option>
              </select>
            </div>
          </div>
          <div className="size-container">
            <div className="size">
            <span>*</span>
            <label htmlFor="size">Size/Duration:</label>
            <Select
            id='size'
            className='size-select'
            options={sizeOptions}
            value={sizeOptions.find(option => option.value === size)}
            onChange={(selectedOption) => setSize(selectedOption.value)}
            placeholder="Size/Duration"
            isSearchable
            required
          />
          </div>
          <span>If Social but running video select video duration</span>
        
        </div>

          </div>

          <div className="optional-options">
            <h5>OPTIONAL</h5>
            <div className="optional-options-container">
              <div>
              <label htmlFor="version" className='input-label'>Version:</label>
              <input
                id='version'
                type="text"
                name="version"
                placeholder="Version"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              />
              </div>
              <div className="color other-container">
                <div>
                <label className='input-label' htmlFor="color">Color:</label>
                <select
                  name="color"
                  id="color"
                  onChange={handleColorChange}
                  value={color}
                >
                  <option value="" disabled>
                    Color
                  </option>
                  <option value="Red">Red</option>
                  <option value="Blue">Blue</option>
                  <option value="Yellow">Yellow</option>
                  <option value="Green">Green</option>
                  <option value="Orange">Orange</option>
                  <option value="Purple">Purple</option>
                  <option value="Black">Black</option>
                  <option value="White">White</option>
                  <option value="Pink">Pink</option>
                  <option value="Grey">Grey</option>
                  <option value="Other">Other</option>
                </select>
                </div>
                {colorOtherVisible && (
                  <input
                    type="text"
                    name="colorOther"
                    placeholder="Write color name"
                    value={colorOther}
                    onChange={(e) => setColorOther(e.target.value)}
                  />
                )}
              </div>

              <div className="call-to-action other-container">
                <div>
                <label className='input-label' htmlFor="action">Call To Action:</label>
                <select
                  name="callToAction"
                  id="action"
                  onChange={handleActionChange}
                  value={action}
                >
                  <option value="" disabled>
                    Call To Action
                  </option>
                  <option value="Signup">Sign Up</option>
                  <option value="Sign On">Sign On</option>
                  <option value="Get The Details">Get The Details</option>
                  <option value="Get A Quote">Get a Quote</option>
                  <option value="Free Trial">Free Trial</option>
                  <option value="Buy Now">Buy Now</option>
                  <option value="Try Free">Try Free</option>
                  <option value="Join Free">Join Free</option>
                  <option value="Open An Account">Open An Account</option>
                  <option value="Try For Free">Try For Free</option>
                  <option value="Start Free Trial">Start Free Trial</option>
                  <option value="Watch Demo">Watch Demo</option>
                  <option value="Start Now">Start Now</option>
                  <option value="Get Started">Get Started</option>
                  <option value="Subscribe Today">Subscribe Today</option>
                  <option value="Join">Join</option>
                  <option value="Install">Install</option>
                  <option value="Visit">Visit</option>
                  <option value="Learn">Learn</option>
                  <option value="Search">Search</option>
                  <option value="Shop">Shop</option>
                  <option value="Other">Other</option>
                </select>
                </div>
                {actionOtherVisible && (
                  <input
                    type="text"
                    name="actionOther"
                    placeholder="If Other Please Specify"
                    value={actionOther}
                    onChange={(e) => setActionOther(e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="buttons">
            <button className='button' onClick={handleClear} id='clear'>Clear Tab</button>
            <button className='button'>Submit</button>
          </div>
        </form>
      </div>
      <div className="button">
        <div>
          <label htmlFor="uploadCSV" className="upload-button">Upload CSV</label>
          <input 
            type="file" 
            accept=".csv"
            onChange={ExtractDetailsFromUploadedFile} 
            className="upload-csv"
          />
        </div>
      </div>
      <br></br>
      <div className="deals-container">
        {fileNames.length > 0 && <h5 className="rendered-heading">Creative Name</h5>}
        <ul className="list-container">
          {fileNames.map((fileName, index) => (
            <div className="single-list" key={index}>
              <li>{fileName}</li>
              <span
                className="cross-icon"
                onClick={() => handleRemove(index)}
              >
                <i className="fa-solid fa-circle-xmark"></i>
              </span>
              <br />
            </div>
          ))}
        </ul>
        <div className="button">
          {fileNames.length > 0 && <button className="download-button" onClick={downloadCSV}>
            CSV
          </button>}
        </div>
      </div>
    </>
  );
}

export default Creatives;
